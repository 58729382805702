<template>
  <router-view/>
</template>

<script>

export default {

}
</script>

<style>
  /* @import 'assets/css/libs/swiper-bundle.min.css'; */
</style>
