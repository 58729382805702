<template>
			<!-- footer-->
			<footer class="footer footer--login">
				<div class="container"> 
					<div class="footer__line"></div>
					<div class="footer__bottom"> 
						<p>&#169; 2021, Kulibin Print. Все права защищены.</p>
						<div class="footer__dgdgf d-flex flex-wrap"><a href="#">Политика конфиденциальности</a><a href="#">Условия обслуживания</a></div>
					</div>
				</div>
			</footer>
			<!-- /footer-->
</template>