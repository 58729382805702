<template>
<div class="advantages">
  <div class="container"> 
    <div class="row"> 
      <div class="advantages__col col-lg-6"><a class="advantages__item" href="#"> 
          <h3 class="advantages__title">Печать <span>в 4 раза </span>быстрее </h3>
          <p class="advantages__text">Скорость печати быстрее всех известных на рынке стандартных принтеров американского и китайского производства без потери в качестве.</p>
          <div class="advantages__more">Узнать больше</div>
          <div class="advantages__img"> 
            <picture>
              <source media="(max-width: 576px)" srcset="../assets/img/advantage_1_sm.webp"><img src="../assets/img/advantage_1.webp" alt="">
            </picture>
          </div></a></div>
      <div class="advantages__col col-lg-6"><a class="advantages__item" href="#"> 
          <h3 class="advantages__title">Регулируемая платформа</h3>
          <p class="advantages__text">Печатная платформа регулируется автоматически и не требует настройки оператором принтера.</p>
          <div class="advantages__more">Узнать больше</div>
          <div class="advantages__img"> 
            <picture>
              <source media="(max-width: 576px)" srcset="../assets/img/advantage_2_sm.webp"><img src="../assets/img/advantage_2.webp" alt="">
            </picture>
          </div></a></div>
      <div class="advantages__col col-lg-6"><a class="advantages__item" href="#"> 
          <h3 class="advantages__title">Автодолив полимера</h3>
          <p class="advantages__text">С помощью автоматической системы долива расходного материала принтер позволяет пользователю практически не контролировать процесс.</p>
          <div class="advantages__more">Узнать больше</div>
          <div class="advantages__img"> 
            <picture>
              <source media="(max-width: 576px)" srcset="../assets/img/advantage_3_sm.webp"><img src="../assets/img/advantage_3.webp" alt="">
            </picture>
          </div></a></div>
      <div class="advantages__col col-lg-6"><a class="advantages__item" href="#"> 
          <h3 class="advantages__title">Не требует постобработки</h3>
          <p class="advantages__text">Вы экономите на дополнительном оборудовании для промывки готового изделия и его дозасветки после печати.</p>
          <div class="advantages__more">Узнать больше</div>
          <div class="advantages__img"> 
            <picture>
              <source media="(max-width: 576px)" srcset="../assets/img/advantage_4_sm.webp"><img src="../assets/img/advantage_4.webp" alt="">
            </picture>
          </div></a></div>
    </div>
  </div>
</div>
</template>