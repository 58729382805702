<template>
<div class="content">
  <!-- gallery-->
  <div class="gallery">
    <div class="container"> 
      <div class="title">Галерея</div>
      <div class="row gy-1">
        <div class="col-lg-6"> <a class="gallery__item gallery__item--big" href="@/assets/img/gallery_1.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Корпус&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Закрытый со всех сторон металлический корпус с двумя распашными смотровыми дверцами&lt;/div&gt;"><img src="@/assets/img/gallery_1.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__content"> 
                <div class="gallery__title">Корпус</div>
                <div class="gallery__text">Закрытый со всех сторон металлический корпус с двумя распашными смотровыми дверцами</div>
              </div>
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-6"> 
          <div class="row gy-1">
            <div class="col-lg-6"><a class="gallery__item gallery__item--mini" href="../assets/img/gallery_2.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_2.webp" alt="">
                <div class="gallery__over"> 
                  <div class="gallery__icon"> </div>
                </div></a></div>
            <div class="col-lg-6"><a class="gallery__item gallery__item--mini" href="../assets/img/gallery_4.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_4.webp" alt="">
                <div class="gallery__over"> 
                  <div class="gallery__icon"> </div>
                </div></a></div>
            <div class="col-12"> <a class="gallery__item gallery__item--small" href="../assets/img/gallery_3.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Платформа&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;С автоматической калибровкой, не требует дополнительной настройки&lt;/div&gt;"><img src="../assets/img/gallery_3.webp" alt="">
                <div class="gallery__over"> 
                  <div class="gallery__content"> 
                    <div class="gallery__title">Платформа</div>
                    <div class="gallery__text">С автоматической калибровкой, не требует дополнительной настройки</div>
                  </div>
                  <div class="gallery__icon"> </div>
                </div></a></div>
          </div>
        </div>
        <div class="col-lg-4"> <a class="gallery__item gallery__item--small" href="../assets/img/gallery_5.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_5.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-4"> <a class="gallery__item gallery__item--small" href="../assets/img/gallery_6.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_6.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-4"> <a class="gallery__item gallery__item--small" href="../assets/img/welcome_sm.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/welcome_sm.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-6"> 
          <div class="row gy-1">
            <div class="col-lg-6"><a class="gallery__item gallery__item--mini" href="../assets/img/gallery_2.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_2.webp" alt="">
                <div class="gallery__over"> 
                  <div class="gallery__icon"> </div>
                </div></a></div>
            <div class="col-lg-6"><a class="gallery__item gallery__item--mini" href="../assets/img/gallery_4.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_4.webp" alt="">
                <div class="gallery__over"> 
                  <div class="gallery__icon"> </div>
                </div></a></div>
            <div class="col-12"> <a class="gallery__item gallery__item--small" href="../assets/img/gallery_3.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Платформа&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;С автоматической калибровкой, не требует дополнительной настройки&lt;/div&gt;"><img src="../assets/img/gallery_3.webp" alt="">
                <div class="gallery__over"> 
                  <div class="gallery__content"> 
                    <div class="gallery__title">Платформа</div>
                    <div class="gallery__text">С автоматической калибровкой, не требует дополнительной настройки</div>
                  </div>
                  <div class="gallery__icon"> </div>
                </div></a></div>
          </div>
        </div>
        <div class="col-lg-6"> <a class="gallery__item gallery__item--big" href="../assets/img/gallery_1.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Корпус&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Закрытый со всех сторон металлический корпус с двумя распашными смотровыми дверцами&lt;/div&gt;"><img src="../assets/img/gallery_1.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__content"> 
                <div class="gallery__title">Корпус</div>
                <div class="gallery__text">Закрытый со всех сторон металлический корпус с двумя распашными смотровыми дверцами</div>
              </div>
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-4"> <a class="gallery__item gallery__item--small" href="../assets/img/gallery_5.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_5.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-4"> <a class="gallery__item gallery__item--small" href="../assets/img/gallery_6.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/gallery_6.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__icon"> </div>
            </div></a></div>
        <div class="col-lg-4"> <a class="gallery__item gallery__item--small" href="../assets/img/welcome_sm.webp" data-fancybox="gallery" data-caption="&lt;div class='gallery__titlefancy'&gt;Особенности принтера&lt;/div&gt;&lt;div class='gallery__textfancy'&gt;Насос для поддержания уровня материала&lt;/div&gt;"><img src="../assets/img/welcome_sm.webp" alt="">
            <div class="gallery__over"> 
              <div class="gallery__icon"> </div>
            </div></a></div>
      </div>
    </div>
  </div>
  <!--	/gallery-->
  <!-- callback-->
  <div class="callback">
    <div class="container"> 
      <div class="callback__block">
        <div class="callback__img"><img src="../assets/img/callback.webp" alt=""></div>
        <div class="callback__content"> 
          <h2 class="callback__title">Хотите узнать больше о наших принтерах или материалах?</h2>
          <p class="callback__text">Укажите свои контактные данные и наш менеджер свяжется с вами для консультации</p>
          <form class="callback__form" action="#">
            <div class="callback__input"> 
              <input type="text" placeholder="Введите номер телефона">
            </div>
            <button class="btn btn--red btn--big" type="submit">Отправить заявку</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--	/callback-->
</div>
</template>

<style>
  @import '../assets/css/libs/fancybox.css'
</style>