<template>
<div class="content">
  <!-- innovation-->
  <div class="innovation">
    <div class="container">
      <h1 class="title">Инновации</h1>
      <p class="innovation__text">Мы уже давно прошли век корпораций и крупных фабрик, владеющих всеми производственными ресурсами. Возможность купить 3D принтер позволяет нам с вами производить свои собственные изделия в любом количестве и в любом месте.</p>
    </div>
    <div class="innovation__sliders"> 
      <div id="sliderInnovation1">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="innovation__item">Доступное оборудование</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Многообразие материалов</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Снижение затрат</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Персонализация</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Сокращение отходов</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Короткие сроки производства</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Геометрическая свобода</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Доступное производство</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Экономическая выгода</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Широкий спектр применения</div>
          </div>
        </div>
      </div>
      <div id="sliderInnovation2">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="innovation__item">Доступное оборудование</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Многообразие материалов</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Снижение затрат</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Персонализация</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Сокращение отходов</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Короткие сроки производства</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Геометрическая свобода</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Доступное производство</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Экономическая выгода</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Широкий спектр применения</div>
          </div>
        </div>
      </div>
      <div id="sliderInnovation3">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="innovation__item">Широкий спектр применения</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Экономическая выгода</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Доступное производство</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Геометрическая свобода</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Короткие сроки производства</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Сокращение отходов</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Персонализация</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Снижение затрат</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Многообразие материалов</div>
          </div>
          <div class="swiper-slide">
            <div class="innovation__item">Доступное оборудование</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--	/innovation-->
  <!-- advantages-->
  <advantages-block />
  <!--	/advantages-->
  <!-- halfitems-->
  <div class="halfitems">
    <div class="container">
      <h2 class="halfitems__title1">Перспективные технологии, над которыми мы работаем</h2>
      <div class="row gy-4">
        <div class="col-lg-6"><a class="halfitems__item" href="#"> 
            <div class="halfitems__content">
              <div class="halfitems__title">Печать без подготовки в 1 клик</div>
              <div class="halfitems__text">Больше не нужно подготавливать модель вручную. Наша система позволит печатать модели в один клик вне зависимости от навыков и умений оператора.</div>
            </div>
            <div class="halfitems__img"> <img src="../assets/img/halfitems_3.webp" alt=""></div></a></div>
        <div class="col-lg-6"><a class="halfitems__item" href="#">
            <div class="halfitems__content">
              <div class="halfitems__title">Cистема блокчейн-контрактов</div>
              <div class="halfitems__text">Система для обмена, продажи, а также печати в один клик трехмерных моделей на платформе крипто токене Ethereum.</div>
            </div>
            <div class="halfitems__img"> <img src="../assets/img/halfitems_4.webp" alt=""></div>
            <div class="halfitems__circle">
              <svg width="216" height="174" viewBox="0 0 216 174" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_78_5977)">
                  <circle cx="119" cy="132" r="119" fill="#D83A56"></circle>
                </g>
                <defs>
                  <clipPath>
                    <path d="M0 0H216V158C216 166.837 208.837 174 200 174H0V0Z" fill="white"></path>
                  </clipPath>
                </defs>
              </svg>
            </div></a></div>
      </div>
    </div>
  </div>
  <!--	/halfitems-->
  <!-- callback-->
  <callback-window />
  <!--	/callback-->
</div>
</template>

<script>
import AdvantagesBlock from "./AdvantagesBlock.vue"
import CallbackWindow from "./elements/CallbackWindow.vue"

export default {
  name: 'MainPage',
  components: {AdvantagesBlock, CallbackWindow}
}
</script>